<template>
  <div v-if="$userInfo">
    <app-detail-header
      :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <item-list-side-menu
          v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table
              class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr
                v-for="(entity, index) in entitySummaries"
                :key="entity.id"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>
                  <a
                    @click.prevent="edit(entity.id, entity.source, $event)"
                    :href="generateUrl(entity.id)">{{ entity.itemNo }}</a>
                </td>
                <td>{{ entity.itemDesc }}</td>
                <td>{{ entity.itemType }}</td>
                <td class="has-text-centered">
                  <span
                    class="icon"
                    :class="[ entity.isCustom ? 'has-text-success' : 'has-text-danger' ]">
                    <i
                      class="mdi mdi-24px"
                      :class="[ entity.isCustom ? 'mdi-check' : '' ]" />
                  </span>
                </td>
                <td class="has-text-centered">
                  <a
                    class="button is-primary is-small is-inverted tooltip"
                    @click.prevent="edit(entity.id, entity.source, $event)"
                    :href="generateUrl(entity.id)"
                    data-tooltip="Click to edit">
                    <span class="icon is-medium">
                      <i class="mdi mdi-lead-pencil mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import ItemListSideMenu from './ItemListSideMenu'
import ItemService from './ItemService'
import { AppHeaderButtonTypes } from '@/enums'
import StoreMixin from './storeMixin'
import ItemValidation from './ItemValidation'
import BulmaTable from '@/components/BulmaTable'
import { Columns } from './columns'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import _transform from 'lodash/transform'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'ItemListView',
  components: {
    AppDetailHeader,
    [ItemListSideMenu.name]: ItemListSideMenu,
    BulmaTable
  },
  filters: {},
  mixins: [ItemValidation, StoreMixin],
  data() {
    return {
      entitySummaries: {},
      totalRows: 0,
      filter: {
        itemNo: '',
        itemDesc: '',
        type: '',
        status: 2,
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      selectedRow: null,
      isTableLoading: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    this.$v.filter.$touch()
  },
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    generateUrl(id) {
      return `items/${id}`
    },
    edit(id, source, event) {
      this.$router.push({
        name: 'ItemDetail',
        params: { id: id, source: source }
      })
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addNew()
          break
        case AppHeaderButtonTypes.Print:
          this.print1()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    filterList() {
      if (this.$v.filter.$error) {
        this.$notification.openNotificationWithType('danger', 'Item', 'Filter validation errors.')
        return false
      }
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    resetList() {
      this.filter.itemNo = ''
      this.filter.itemDesc = ''
      this.filter.type = ''
      this.filter.status = 2
      this.filter.sortColumn = Columns[0].name
      this.filter.sortOrder = Columns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.persistFilter()
      this.getData()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getData()
    },
    async getData() {
      // this.$showSpinner()
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await ItemService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
      // this.$hideSpinner()
    },
    print() {
      const params = Object.assign(
        {
          reportname: this.$route.meta.report,
          companyId: this.$userInfo.companyId
        },
        this.filter
      )
      params.pageIndex = 1
      params.pageSize = this.totalRows
      const paramsFlat = Object.keys(params)
        .map(e => `${e.toLowerCase()}=${params[e]}`)
        .join('&')
        .replace('&sortorder', '&sortdirection')
      const url = `${this.$route.meta.reportUrl}?${paramsFlat}`
      window.open(url, '_blank')
    },
    print1() {
      let params = Object.assign(
        {
          companyId: this.$userInfo.companyId,
          countrycode: 'AU'
        },
        this.filter
      )
      params.pageIndex = 1
      params.pageSize = this.totalRows
      // const query = this.$lzstring.compressToEncodedURIComponent(
      //   Object.keys(params)
      //     .map(e => `${e.toLowerCase()}=${params[e]}`)
      //     .join('&')
      //     .replace('&sortorder', '&sortdirection')
      // )
      params = _transform(params, function(result, value, key) {
        if (key.toLowerCase() === 'sortorder') {
          result['sortdirection'] = value
        } else {
          result[key.toLowerCase()] = value
        }
      })
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)
      const emailer = new Emailer()
      emailer.subject = 'Item Listing'
      emailer.reportName = 'ItemList'
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    async addNew() {
      this.addStoreItem()
    }
  }
}
</script>
