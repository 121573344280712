import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    labourGroup: ['entity.itemNo', 'entity.itemDesc'],
    partGroup: ['entity.itemNo', 'entity.itemDesc', 'entity.side'],
    opgGroup: ['entity.itemNo', 'entity.itemDesc'],
    miscGroup: ['entity.itemNo', 'entity.itemDesc'],
    subletGroup: ['entity.itemNo', 'entity.itemDesc'],
    entity: {
      itemNo: {
        required,
        maxLength: maxLength(5)
      },
      itemDesc: {
        required,
        maxLength: maxLength(50)
      },
      side: {
        required
      }
    },
    filter: {}
  }
}
